import React from "react";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Section from "../../components-v2/Base/Section";
import Heading from "../../components-v2/Base/HeadingBuilder";
import heroBg from "../../images/general-pos-og.png";
import LeadForm from "../../components/Forms/LeadForm";
import data from "../../data/forms/lead-form";
import { extractDefaultValues } from "../../utils/form-utils";
import bg1 from "../../images/svg/vector-10.svg";
import b1 from "../../images/random/badge-101.png";
import b2 from "../../images/random/badge-102.png";
import b3 from "../../images/random/badge-103.png";
import b4 from "../../images/random/badge-104.png";
import b5 from "../../images/random/badge-105.png";
import TestmonialReviews from "../../components/TestimonialReviews/TestmonialReviews";
import TopRestaurantsLogos from "../../components-v2/TopRestaurantsLogos";
import Image from "../../components/ImageQuerys/RandomImages";
import { SAASQUATCH_MERCHANT_REFERRAL } from "../../utils/firebase/forms";
import check from "../../images/svg/checkmark.svg";
import reviews from "../../data/reviews";

function MerchantReferral({}) {
  return (
    <Layout
      className="non-fixed-width"
      isLanding
      useNewLPHeader
      phoneNumber="+18882246506"
      phoneText="(888) 224-6506"
    >
      <SEO
        title="Client Referral Sign-Up | SpotOn"
        description="Whether you need a simple way to take payments or an end-to-end POS solution to run and grow your business, SpotOn has you covered. Get started."
        image={`https://spoton.com/${heroBg}`}
      />

      <Section className="grid grid-cols-1 lg:grid-cols-2 mt-20 md:mt-32 items-center gap-10">
        <div className="text-center lg:text-left">
          <Heading level={1} injectionType={1} withBlueSymbol>
            Save up to $500 on a new POS
          </Heading>
          <div className="text-black-300 text-[20px] max-w-[400px] mt-10 mx-auto lg:mx-0 relative z-1">
            You’ve been recommended to SpotOn for good reason. Let’s see how we
            can help you save time and money with intuitive technology.
          </div>

          <div>
            <Heading
              withBlueSymbol={false}
              level={2}
              injectionType={4}
              className="text-black max-w-[800px] mx-auto mt-8 relative z-1 hidden md:block"
            >
              Committed to helping your business grow through innovation and
              unmatched customer support.
            </Heading>
            <div className="mt-8 md:mt-0 mx-auto flex flex-wrap gap-3 items-center justify-between md:justify-between lg:justify-start xl:justify-between">
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b1}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b2}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b3}
                alt="badge"
              />
              <img
                className="max-w-[22%] md:max-w-[100px]"
                src={b4}
                alt="badge"
              />
              <img
                className="hidden md:inline max-w-[22%] md:max-w-[100px]"
                src={b5}
                alt="badge"
              />
            </div>
          </div>
        </div>

        <div className="relative">
          <LeadForm
            className="relative z-[1]"
            plain
            noHeader
            formName="Lead Form"
            displayForm
            formData={data}
            initialValues={extractDefaultValues(data)}
            onValuesChange={() => null}
            leadType="restaurants"
            formValueForDataLayerEventName="industry"
            dataLayerEventNameAppendix="-lead"
            withLineOfBusinessOption={false}
            lineOfBusiness="Hospitality"
            productInterfest=""
            collection={SAASQUATCH_MERCHANT_REFERRAL}
            thankYouPath="/merchant-referral/thank-you"
            formCta="Submit"
          />
          <img
            src={bg1}
            alt="blue circle"
            className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
          />
        </div>
      </Section>

      <Section fullWidth className="bg-black mt-10">
        <div className="text-white grid grid-cols-1 lg:grid-cols-2 gap-y-10 gap-x-4 max-w-[1000px] mx-auto lg:py-40">
          <div className="text-center mx-auto w- lg:mx-0 lg:text-left">
            <Heading
              className="text-white"
              withBlueSymbol
              level={2}
              injectionType={1}
            >
              Technology that works the way you work
            </Heading>

            <p className="text-black-600 text-p lg:max-w-[380px]">
              Whether you need a simple way to take payments or an end-to-end
              POS solution with built-in tools to run and grow your business,
              SpotOn is here to help.
            </p>
            <div className="font-bold lg:max-w-[400px] gap-y-6 flex flex-col mt-8">
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Flexible point-of-sale and payment solutions for any type of
                  business
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Built in email marketing, social media, and review management
                  tools
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Comprehensive, cloud-based reporting and dashboards
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Optional loyalty, labor management, and waitlist & reservation
                  solutions
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Real humans to set it all up for you, with 24/7 support after
                  launch
                </span>
              </div>
              <div className="flex flex-row items-center">
                <img alt="checkmark" src={check} className="mr-4" />
                <span className="text-left">
                  Transparent pricing with no long-term commitment and no junk
                  fees
                </span>
              </div>
            </div>
          </div>

          <div className="relative -mb-32 ">
            <Image
              imageName="merchant-referral-asset.png"
              alt="products"
              className="relative lg:absolute left-0 right-0 top-0"
            />
          </div>
        </div>
      </Section>

      <Section className="mt-40 xl:mt-48">
        <TestmonialReviews
          sectionData={{
            reviews,
            title: "What SpotOn customers are saying",
            subTitle: "Read some of our 1500+ five star customer reviews.",
          }}
        />
      </Section>

      <TopRestaurantsLogos
        desktopImageName="merchant-referral-logos-desktop.png"
        mobileImageName="merchant-referral-logos-mobile.png"
      />
    </Layout>
  );
}

export default MerchantReferral;
